html {
  font-size: 62.5%;
}
body {
  font-size: 1.4rem;
  font-weight: 400;
  color: $colorDark;
  font-family: $fontHeading;
}

.body-font {
  font-family: $fontBody;
}

.blink-loader {
  animation: blinker 2s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.r-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background-color: rgba($color: #000000, $alpha: 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
  &__cap {
    position: absolute;
    top: -16px;
    width: 55px;
    left: -13px;
    z-index: 1;
    animation: toss-cap 1.1s linear infinite;
  }
  &__reculta {
    width: 60px;
    animation: toss-reculta 1.1s linear infinite;
  }
}

@keyframes toss-cap {
  0% {
    transform: rotate(0deg);
    top: -16px;
  }
  10% {
    transform: rotate(-20deg);
    top: -21px;
  }
  35% {
    transform: rotate(180deg);
    top: -60px;
  }
  70% {
    transform: rotate(360deg);
    top: -16px;
  }
  100% {
    transform: rotate(360deg);
    top: -16px;
  }
}

@keyframes toss-reculta {
  0% {
    transform: translateY(0);
  }
  5% {
    transform: translateY(-5px) rotate(-20deg);
  }
  13% {
    transform: translateY(-10px) rotate(0deg);
  }
  20% {
    transform: translateY(0) rotate(0deg);
  }
  100% {
    transform: translateY(0) rotate(0deg);
  }
}

.r-error {
  border: 1px solid $colorError;
  padding: 5px 10px;
  position: relative;
  &::after {
    content: "\2716";
    position: absolute;
    color: $colorError;
    right: 5px;
    font-size: 0.8em;
    font-weight: 600;
    border: 1px solid $colorError;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    text-align: center;
  }
}

.r-upload {
  display: inline-flex;
  border: 1px solid $colorGrey3;
  width: auto;
  padding: 3px 5px;
  border-radius: 3px;
  align-items: center;
  justify-content: center;
  &__divider {
    background-color: $colorGrey3;
    width: 1px;
    height: 30px;
    margin: 0 10px;
  }
  &__newFile,
  &__browse {
    text-align: center;
    position: relative;
    overflow: hidden;
  }
  &__upload {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    height: 100%;
    width: 100%;
    cursor: pointer;
    text-indent: 100%;
    &:focus {
      outline: none;
    }
  }
}

.fixed-sidebar {
  position: absolute;
  left: 15px;
  width: calc(100% - 30px);
}

.heading-dropdown {
  .r-dropdown {
    font-size: 2.8rem;
    border: none;
    &__select {
      padding-right: 50px;
      &__list {
        max-height: 250px;
        overflow: auto;
      }
    }
    i {
      font-size: inherit;
      color: inherit;
    }
  }
}
.modal {
  .modal-dialog {
    &.modal {
      &--delete,
      &--centered {
        top: 50%;
        transform: translateY(-50%);
      }
      &--delete {
        max-width: 300px;
      }
    }
    .close {
      font-size:  2.2rem;
    }
  }
  &-md {
    max-width: 650px;
  }
}

.error-bar {
position:fixed;

	z-index: 100000;
	background-color: #fecfc4;
	color:#fa3709;

	width:100%;
	box-shadow:0 0 4px 0px $colorGrey4;
	top:0%;
  left:0%;
}


.success-bar {
position:fixed;

  z-index: 100000;
  background-color: #d4fec4;
  color:#415857;

  width:100%;
  box-shadow:0 0 4px 0px $colorGrey4;
  top:0%;
  left:0%;
}


.close-bn {
  font-size: 1.6rem;
  font-weight: 800;
  position: absolute;
  right: 10px;
  top: calc(50% - 15px);
}

.shortlist_span {
  display: flex ;
  align-items: center;
  color: "blue"
}



.on-hover-high-zindex:hover {
  z-index : 9999999999;
}

.colleges .college-logo{
    max-width: 80%;
    margin: auto;
  }
 .breadcrumb {
    background-color: #ffffff;
}

.breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: .5rem;
    color: #6c757d;
    content: ">";
}

.colleges .card{
    position: relative;
    overflow: hidden;
    height: 100%;
}

.colleges .card .card-overlay {
    background: rgba(0,102,204,0.7);
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    -webkit-transition: all 0.4s ease-in-out 0s;
    -moz-transition: all 0.4s ease-in-out 0s;
    transition: all 0.4s ease-in-out 0s;
  }

.colleges .college-card:hover .card-overlay{
    opacity: 1;
}

.card .shortlist {
    position: absolute;
    top: 5px;
    right: 10px;
    opacity: 0;
    -webkit-transition: all 0.3s ease-in-out 0s;
    -moz-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
}
  
.card:hover .shortlist{
    opacity: 1;
    color: #ffffff;
    cursor: pointer;
}

.card:hover .remove{
    opacity: 1;
    color: #000000 !important;
    cursor: pointer;
}

 .shortlisted{
    border: 1px solid #06c;
}


.ribbon {
    width: 25px;
    height: 25px;
    overflow: hidden;
    position: absolute;
}

.ribbon span {
    position: absolute;
    display: block;
    width: 80px;
    padding: 15px 0;
    background-color: #06c;
}
/* top right*/
.ribbon-top-right {
    top: 0px;
    right: 0px;
}

.ribbon-top-right span {
    transform: rotate(45deg);
} 


.arrow:after {
	bottom: 100%;
	right: 15%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
	border-color: rgba(213, 213, 213, 0);
	border-bottom-color: #f5f5f5;
	border-width: 15px;
	margin-left: -15px;
}
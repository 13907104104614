.r- {
  &inputfield {
    background-color: $colorGrey1;
    border: 0.5px solid $colorGrey9;
    font-weight: 300;
    padding: 5px;
    outline: none;
    &:-webkit-autofill {
      -webkit-animation-name: autofill;
      -webkit-animation-fill-mode: both;
    }
    &.r-inputfield__error {
      box-shadow: 0 0 3px 1px rgba($color: $colorBrandF, $alpha: 0.5)
    }
    &-2 {
      background-color: transparent;
      border: none;
      border-bottom: 1px solid $colorDark;
      padding: 0 10px;
      &:focus {
        outline: 0;
      }
      &--icon {
        position: absolute;
        top: 0;
        right: 5px;
      }
    }
  }
  &inputfield_2 {
    background-color: $colorLight;
    border: 0.5px solid $colorGrey9;
    font-weight: 300;
    padding: 5px;
    margin-bottom: 15px;
    outline: none;
    &:-webkit-autofill {
      -webkit-animation-name: autofill;
      -webkit-animation-fill-mode: both;
    }
    &::placeholder {
      color: $blue3;
      opacity: 1; /* Firefox */
    }
    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: $blue3;
     }
     
    &::-ms-input-placeholder { /* Microsoft Edge */
      color: $blue3;
     }
  }
  &input_field_icon{
    position: absolute;  
    margin-left: -25px;
    margin-top: 5px;  
    color: #e5e5e5;
    
  }
  &check {
    display: none;
    & + label {
      cursor: pointer;
      position: relative;
      padding-left: 15px;
      &::before {
        content: "";
        position: absolute;
        width: 11px;
        height: 11px;
        border: 1px solid $colorGrey3;
        border-radius: 2px;
        background-color: $colorLight;
        left: 0;
        top: 4px;
      }
    }
    &:checked {
      & + label {
        &::after {
          content: "";
          position: absolute;
          width: 7px;
          height: 3px;
          left: 2px;
          top: 7px;
          transform: rotate(-45deg);
          border-style: solid;
          border-width: 0 0 1px 1px;
        }
      }
    }
  }
  &dropdown {
    border: 1px solid $colorGrey3;
    position: relative;
    select {
      
      background-color: $colorLight;
    }
    &__select {
      border: none;
      padding: 5px 22px 5px 5px;
      width: 100%;
      /* for Firefox */
      -moz-appearance: none;
      /* for Chrome */
      -webkit-appearance: none;
      cursor: pointer;
      /* For IE10 */
      &::-ms-expand {
        display: none;
      }
      &__list {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        min-width: 100%;
        background-color: $colorLight;
        box-shadow: 0 0 5px 2px rgba($color: #000000, $alpha: 0.09);
        padding: 0 12px;
        z-index: 1;
        ul {
          li {
            padding: 5px 0;
            border-bottom: 1px solid $colorGrey3;
            cursor: pointer;
            white-space: nowrap;
            &:last-child {
              border: none;
            }
          }
        }
        &--show {
          display: block;
        }
      }
    }
    i {
      position: absolute;
      top: 8px;
      right: 10px;
      font-size: 1rem;
      color: $colorGrey4;
      transform: rotate(90deg);
      pointer-events: none;
      z-index: 0;
    }
    &-2 {
      .r-dropdown {
        border: none;
        display: inline-block;
        &__select {
          background-color: #f5f5f5;
          border-radius: 3px;
          border: 1px solid #d6d6d6;
          font-size: 1.2rem;
        }
      }
    }
  }
  &tag {
    background-color: $colorGrey1;
    border-radius: 5px;
    padding: 2px 25px 2px 10px;
    margin-bottom: 10px;
    position: relative;
    font-family: $fontHeading;
    a {
      color: inherit;
      text-decoration: none;
    }
    &__delete {
      cursor: pointer;
      outline: none;
      padding: 0;
      border: none;
      background-color: transparent;
      font-size: 1rem;
      top: 5px;
      position: absolute;
      right: 10px;
      transform: rotate(45deg);
      &:focus,
      &:active {
        outline: none;
      }
    }
    &--type-1 {
      border-radius: 6px;
      border: 1px solid $colorGrey3;
      font-size: 1.2rem;
      .r-tag__delete {
        top: 3px;
      }
    }
    &--no-delete {
      padding-right: 10px;
    }
  }
  &stars {
    color: $colorGrey3;
    &--edit {
      padding: 5px 0;
      & > span {
        cursor: pointer;
      }
    }
    .fi.active {
      color: $colorBrandE;
    }
  }
  &multifield {
    background-color: $colorGrey2;
    padding: 5px;
    &__list {
      list-style: none;
      padding: 0;
      display: flex;
      flex-wrap: wrap;
      li {
        padding: 5px;
        .r-tag {
          background-color: $colorGrey3;
        }
      }
    }
  }
  &toggle {
    font-size: 1.2rem;
    font-weight: 600;
    display: inline-flex;
    align-items: center;
    color: $blue3;
    &__btn {
      display: inline-block;
      padding: 4px 14px;
      background-color: $blue3;
      border-radius: 3px;
      border: 3px solid $blue3;
      position: relative;
      cursor: pointer;
      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 60%;
        height: 100%;
        border-radius: 3px;
        background-color: $colorLight;
        transition: left 0.3s ease-in-out;
      }
    }

    &--selected-1 {
      .r-toggle {
        &__value:last-child {
          opacity: 0.6;
        }
      }
    }
    &--selected-2 {
      .r-toggle {
        &__value:first-child {
          opacity: 0.6;
        }
        &__btn::before {
          left: 40%;
        }
      }
    }
  }
  &btn-dropdown {
    .dropdown-toggle {
      background-color: $colorGrey2;
      color: $colorDark;
      font-size: 1.4rem;
      font-weight: 500;
      border: none;
      padding: 0 10px;
      &::after {
        content: "\f121";
        font-family: "flaticon";
        transform: rotate(90deg);
        vertical-align: middle;
        border: none;
        margin-left: 18px;
      }
    }
    .dropdown-menu {
      font-size: 1.4rem;
    }
  }
  @-webkit-keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  &rte {
    .ql-snow .ql-color-picker .ql-picker-label,
    .ql-snow .ql-icon-picker .ql-picker-label {
      padding: 0px 4px;
    }
    .ql-toolbar.ql-snow .ql-formats {
      margin-right: 5px;
    }
    .ql-toolbar.ql-snow .ql-formats {
      margin-right: 0px;
    }
    * {
      font-family: $fontHeading;
    }
    .ql-editor.ql-blank::before {
      font-style: normal;
    }
    .ql-container {
      background: $colorGrey1;
      border: 0.5px solid #e5e5e5;
    }
    .ql-editor {
      padding-left: 12px;
    }
    &-toolbar-hide {
      .ql-toolbar {
        height: 0;
        overflow: hidden;
        padding: 0;
        border: none;

        transition: all 0.3s ease-in-out;
      }
      &:focus-within {
        .ql-toolbar {
          height: 42px;
          padding: 8px;
          overflow: visible;
          border: 0.5px solid #e5e5e5;
        }
      }
    }
  }
  &richtext {
    p {
      margin: 0;
    }
  }
}
// Date Picker Styles

.DateInput {
  &_input {
    background-color: $colorGrey2;
    border: none;
    font-weight: 300;
    font-size: 14px;
    padding: 5px;
    line-height: inherit;
  }
}
.DateRangePickerInput {
  display: flex;
  .DateInput {
    width: 50%;
    &_input {
      width: 100%;
    }
  }
  &_arrow {
    display: none;
  }
  .CalendarDay {
    &__default {
      color: inherit;
      border-color: $colorGrey3;
    }
    &__selected,
    &__hovered {
      &_span {
        background-color: $colorLight;
      }
      & {
        color: $colorLight;
        background-color: #00a699;
      }
    }
  }
}
.DayPicker {
  .CalendarMonth {
    &_caption {
      font-size: 12px;
      padding-bottom: 50px;
      strong {
        font-weight: 600;
      }
    }
  }
  &_weekHeader {
    &_li {
      small {
        font-size: 12px;
        font-weight: 600;
      }
    }
  }
}
@-webkit-keyframes autofill {
  to {
    background: $colorGrey2;
  }
}

// Time Picker
.rc-time-picker-panel {
  z-index: 999999!important;
}

// Autosuggest Styles
.react-autosuggest {
  &__container {
    position: relative;
  }
  &__suggestions {
    &-container {
      position: absolute;
      left: 0;
      top: 30px;
      width: 100%;
      height: 0;
      overflow: hidden;
      opacity: 0;
      background-color: $colorLight;
      z-index: 2;
      border: 1px solid $colorGrey3;
      transition: height 0.3s ease-in-out, opacity 0.3s ease-in-out;
      &--open {
        height: 200px;
        overflow-y: auto;
        opacity: 1;
      }
    }
    &-list {
      list-style: none;
      padding: 0;
      margin: 0;
    }
  }
  &__suggestion {
    padding: 5px;
    border-bottom: 1px solid $colorGrey2;
    cursor: pointer;
    &--highlighted {
      background-color: $colorGrey2;
    }
  }
}


.post .card{
    position: relative;
    overflow: hidden;
    padding: 15px;
}



.statistics{
    background-color: #0066cc;
    color: #ffffff;
}
.statistics .counter{
    background-color: rgba(255,255,255,0.5);
    text-align: center;
    padding: 10px 0;
}

.statistics .graph{
    width: 100%;
    max-width: 100%;
}

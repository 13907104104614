/* search */
.search-box {
  margin-top: 5%;
  text-align: center;
}
.search-horizontal {
  list-style: none;
  position: relative;
  display: inline-block;
  padding-top: 50px;
}

.search {
  &:before {
    top: 40px;
    bottom: 0;
    position: absolute;
    content: " ";
    width: 5px;
    background-color: #0066cc;
  }
  .search-item .search-badge {
    color: #fff;
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 18px;
    text-align: center;
    position: absolute;
    top: 18px;
    background-color: #0066cc;
    border: none;
    z-index: 100;
    border-radius: 50%;
  }
}

.search-horizontal {
  &:before {
    height: 8px;
    top: auto;
    bottom: 20px;
    left: 45px;
    right: 0;
    width: 68%;
  }
  .search-item {
    display: table-cell;
    height: 50px;
    min-width: 150px;
    .search-badge {
      top: auto;
      bottom: 0px;
    }
  }
}

.search-heading {
  position: relative;
  padding-left: 10px;
  text-align: left;
  top: 55px;
}

.search .brand-logo {
  position: absolute;
  top: -30px;
  margin-left: -34px;
}

.search-panel {
  position: relative;
  text-align: center;
  max-width: 786px;
  margin-left: auto;
  margin-right: auto;
}
.filters {
  height: 100%;
  .btn-group {
    width: 100%;
    height: 100%;
    .search-filter {
      font-size: 1.4rem;
      border: 1px solid $colorGrey3;
      padding: 5px 22px 5px 5px;
      width: 100%;
      height: 100%;
      background: $colorLight;
      -moz-appearance: none;
      -webkit-appearance: none;
      cursor: pointer;
      color: $colorDark;
      &:focus {
        box-shadow: none;
      }
      i {
        position: absolute;
        right: 10px;
        transform: rotate(90deg);
        font-size: 1.2rem;
        top: 8px;
      }
    }
    .dropdown-menu {
      font-size: 1.4rem;
      width: 100%;
      padding: 5px;
      margin: 0;
    }
  }
}

.search-panel .filters input {
  border-radius: 15px;
  padding: 5px;
  background-color: #fff;
}

.icon-right {
  position: relative;
  margin-left: -25px;
  color: #e5e5e5;
}

.icon-left {
  position: relative;
  color: #e5e5e5;
}

.custom-control-input:checked ~ .custom-control-label::before {
  background-color: #0066cc !important;
  top: 5px;
  cursor: pointer;
}
.custom-control-label::after {
  width: 4px;
  height: 4px;
  border-radius: 10px;
  position: absolute;
  content: "";
  background-color: #fff;
  top: 8px;
  left: -12px;
}

.search-seprator h2 {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #e5e5e5;
  line-height: 0.1em;
  margin: 10px 0 20px;
}

.search-seprator h2 span {
  background: #fff;
  padding: 0 10px;
  color: #e5e5e5;
}

.search-munu {
  position: absolute;
  width: 100%;
}

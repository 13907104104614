
.sidenav{
    position: fixed;
    background-color: #ECF3FD;
    min-width: 225px;
    width: 25%;
    height: calc(100vh - 50px);    
}

.r-inputfield {
    background-color: #f5f5f5;
    border: .5px solid #e5e5e5;
    font-weight: 300;
    padding: 5px;
    outline: none;
}

.search-icon {
    position: absolute;
    margin-left: -25px;
    top: 5px;
}

.minus-bar {
    border-bottom: 2px solid #707070;
    width: 10px;
    display: block;
}

.download img{
    width: 25px;
    height: 25px;
}


.r- {
  &sidenav {
    background-color: $blue1;
    border-radius: 3px;
    color: $blue3;
    position: absolute;
    border: 0.5px solid rgba($color: $blue5, $alpha: 0.19);
    width: calc(100% - 30px);
    left: 15px;
    overflow-x: hidden;
    //border-top-left-radius: 10px;
    max-height: calc(100vh - 80px);
    transition: all 250ms ease-in-out 0s;
    &-fixed{
      position:fixed;
      width: 18%;
      transition: all 250ms ease-in-out 0s;
    }
    &-resume-fixed{
      position:fixed;
      width: 30px;
      left : 7.55%;
    }

    &__expended{
      width:270px!important;
      z-index:999;
      box-shadow: 3px 3px 15px 8px #aaa;
    }
    &__header {
      padding: 15px 10px;
      border-bottom: 1px solid $blue3;
    }
    &__header .justify-content-between {
      flex-wrap: nowrap;
      white-space: nowrap;
    }
    &__body {
      box-shadow : inset 0 0 10px 0px #ccc;
      transition: left 3s;
      ul{
        transition: left 3s;
      }
    }

    &__menu {
      list-style: none;
      padding-left: 0;
      margin: 0;
      &.resumes {
        max-height: calc(100vh - 200px);
        overflow: auto;
      }
      li {
        cursor: pointer;
        width: 100%;
        text-align: left;
        padding: 10px 20px;
        transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
        border-radius: 0;
        flex-wrap: nowrap;
        word-break: break-word;
        &:hover {
          background-color: $blue2;
        }
        & > span {
          font-size: 1.4rem;
          font-weight: 600;
          line-height: 1rem;
        }
        &.sidenav-active {
          &,
          &:hover {
            background-color: $blue3;
            color: $blue1;
          }
        }
      }
    }
  }
}

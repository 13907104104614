.evaluator{
  &__multifield{
    width: 100%;
    background-color: white;
    min-height: 144px;
  }

  &__table{
    // width: 50%;
    height: 144px;
    overflow-y: scroll;
  }

  &__dashboard-button{
    border: none;
    background-color: transparent;

    &.active{
      outline:none;
      border: none;
      border-bottom: 3px solid #ef0065;
      background-color: transparent;
    }
  }
  &__allocate-button{
    font-size: 12px;
    vertical-align: super;
  }
}
